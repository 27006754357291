import React, { useState } from "react"
import { MailIcon, MapIcon, PhoneIcon } from "@heroicons/react/outline"
import armin_voser from "../../images/armin_contact.jpg"
import emailjs from "emailjs-com"
import { useForm } from "react-hook-form"
import AlertSuccess from "../alert-success"
import ModalSuccess from "../modal-success"

export default function ContactForm() {
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [hideForm, setHideForm] = useState(false)

  const handleSubmit = e => {
    console.log(e)
    e.preventDefault()
    let myForm = e.target
    let formData = new FormData(myForm)
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        setFormSubmitted(true)
        setHideForm(true)
        myForm.reset()
      })
      .catch(error => alert(error))
  }

  return (
    <div hidden={hideForm} className="bg-gray-100 mt-12">
      <div className="max-w-7xl mx-auto lg:py-12">
        <div className="relative bg-white shadow-xl">
          <h2 className="sr-only">Kontaktiere uns</h2>
          <div className="grid grid-cols-1 lg:grid-cols-3">
            {/* Contact information */}
            <div className="relative overflow-hidden py-10 px-6 bg-gray-700 sm:px-10 xl:p-12">
              <img
                src={armin_voser}
                className="rounded-full h-42 w-42 lg:w-56 lg:h-56 mb-4"
              />
              <div
                className="absolute inset-0 pointer-events-none sm:hidden"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 w-full h-full"
                  width={343}
                  height={388}
                  viewBox="0 0 343 388"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                    fill="url(#linear1)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear1"
                      x1="254.553"
                      y1="107.554"
                      x2="961.66"
                      y2="814.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 w-full h-full"
                  width={359}
                  height={339}
                  viewBox="0 0 359 339"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                    fill="url(#linear2)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear2"
                      x1="192.553"
                      y1="28.553"
                      x2="899.66"
                      y2="735.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 w-full h-full"
                  width={160}
                  height={678}
                  viewBox="0 0 160 678"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                    fill="url(#linear3)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear3"
                      x1="192.553"
                      y1="325.553"
                      x2="899.66"
                      y2="1032.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <h1 className="text-2xl font-medium text-white mb-0">
                Armin Voser
              </h1>
              <p className="text-base italic text-blue-50 max-w-3xl">
                Ihr Ansprechpartner
              </p>
              <p className="mt-6 text-base text-blue-50 max-w-3xl">
                Ich freue mich über Ihre Anfrage und berate Sie gerne im
                nächsten Schritt.
              </p>
              <dl className="mt-8 space-y-6">
                <dt>
                  <span className="sr-only">Phone number</span>
                </dt>
                <dd className="flex text-base text-blue-50">
                  <PhoneIcon
                    className="flex-shrink-0 w-6 h-6 text-blue-200"
                    aria-hidden="true"
                  />
                  <span className="ml-3">+41 41 521 32 40</span>
                </dd>
                <dt>
                  <span className="sr-only">E-Mail</span>
                </dt>
                <dd className="flex text-base text-blue-50">
                  <MailIcon
                    className="flex-shrink-0 w-6 h-6 text-blue-200"
                    aria-hidden="true"
                  />
                  <span className="ml-3">info@adoser.ch</span>
                </dd>
                <dd className="flex text-base text-blue-50">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-blue-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>{" "}
                  <span className="ml-3">
                    <address className="not-italic">
                      Emmenweidstrasse 64
                      <br />
                      6020 Emmenbrücke
                    </address>
                  </span>
                </dd>
              </dl>
              <ul role="list" className="mt-8 flex space-x-12">
                <li>
                  <a
                    className="text-blue-200 hover:text-blue-100"
                    href="https://www.linkedin.com/company/adoser-gmbh/"
                  >
                    <span className="sr-only">Linkedin</span>
                    <svg
                      className="w-5 h-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>

            {/* Contact form */}
            <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
              <h3 className="text-lg font-bold text-gray-900">
                Senden Sie uns eine Nachricht!
              </h3>
              <form
                action="/"
                method="post"
                onSubmit={handleSubmit}
                name="form-contact-home"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                className="mt-6 grid grid-cols-1
                gap-y-6 sm:grid-cols-2 sm:gap-x-8"
              >
                <div>
                  <label
                    htmlFor="user_firstname"
                    className="block text-md font-medium text-gray-900"
                  >
                    Vorname
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="user_firstname"
                      id="user_firstname"
                      autoComplete="Vorname"
                      className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-indigo-500 focus:border-blue-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="user_lastname"
                    className="block text-md font-medium text-gray-900"
                  >
                    Nachname
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="user_lastname"
                      id="user_lastname"
                      autoComplete="Familienname"
                      className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-indigo-500 focus:border-blue-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="user_email"
                    className="block text-md font-medium text-gray-900"
                  >
                    E-Mail
                  </label>
                  <div className="mt-1">
                    <input
                      id="user_email"
                      name="user_email"
                      type="email"
                      autoComplete="email"
                      className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-indigo-500 focus:border-blue-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <div className="flex justify-between">
                    <label
                      htmlFor="user_phone"
                      className="block text-md font-medium text-gray-900"
                    >
                      Telefon
                    </label>
                    <span id="phone-optional" className="text-sm text-gray-500">
                      Optional
                    </span>
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="user_phone"
                      id="user_phone"
                      autoComplete="tel"
                      className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-indigo-500 focus:border-blue-500 border-gray-300 rounded-md"
                      aria-describedby="phone-optional"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="subject"
                    className="block text-md font-medium text-gray-900"
                  >
                    Betreff
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-indigo-500 focus:border-blue-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label
                      htmlFor="message"
                      className="block text-md font-medium text-gray-900"
                    >
                      Nachricht
                    </label>
                    <span id="message-max" className="text-sm text-gray-500">
                      Max. 500 Wörter
                    </span>
                  </div>
                  <div className="mt-1">
                    <textarea
                      id="message"
                      name="message"
                      rows={4}
                      className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-indigo-500 focus:border-blue-500 border border-gray-300 rounded-md"
                      aria-describedby="message-max"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2 sm:flex sm:justify-end">
                  <input
                    type="hidden"
                    name="form-name"
                    value="form-contact-home"
                  />

                  <button
                    type="submit"
                    className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto"
                  >
                    Absenden
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ModalSuccess
        active={formSubmitted}
        setFormSubmitted={setFormSubmitted}
      />
    </div>
  )
}
