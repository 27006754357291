import * as React from "react"
import HeroSimple from "../components/hero-simple"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PartnerLogos from "../components/partner-logos"
import ContactForm from "../components/contant-form"
import HeroHome from "../components/hero-home"

const IndexPage = () => (
  <Layout>
    <Seo
      title="Startseite"
      description="Erstklassige Leistungen, Qualität und Ihre Zufriedenheit sind unser Ansporn. Ihre Zufriedenheit ist unsere Visitenkarte."
    />
    <HeroHome />
    <div className="max-w-7xl mx-auto px-4 xl:px-0 xl:py-0">
      <HeroSimple
        textAlign={"text-left"}
        title={"Ihre Wünsche in besten Händen"}
        subTitle={`Unsere Erfahrung und das Fachwissen, setzen die Basis für unsere Handwerkskunst. Wir verstehen Ihre Ideen sowie Wünsche und setzen sie mit Freude um.
      Im Sinne unserer Kunden setzen wir, als innovatives Unternehmen, Täglich unsere Leistungen sowie die Wirtschaftlichkeit unter Beweis.
      Mit den gemeinsamen Leistungen setzen wir immer wieder neue Akzente in der modernen Baukultur.
      `}
      />
      <HeroSimple
        textAlign={"text-left"}
        title={"Wir stehen zu dem was wir machen"}
        subTitle={`Genau aus diesem Grund profitieren all unsere Kunden. Um Ihre volle Zufriedenheit über einen langen Zeitraum 
      zu gewährleisten, gehen wir eine langfristige Verpflichtung ein. Mit Garantien bis zu 2 Jahren auf die 
      Installationen und bis zu 10 Jahre auf das Produkt, stellen wir Ihre Zufriedenheit sicher.
      `}
      />

      <PartnerLogos />
    </div>
    <ContactForm />
  </Layout>
)

export default IndexPage
