/* This example requires Tailwind CSS v2.0+ */
import React from "react"

export default function HeroSimple({ title, subTitle, textAlign }) {
  return (
    <div className="bg-white mb-6 md:mb-12">
      <div className="">
        <div className={textAlign}>
          <p className="mt-1 text-3xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-4xl">
            {title}
          </p>
          <p
            className={`${textAlign} mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:mx-auto md:mt-5 md:text-xl lg:mx-0`}
          >
            {subTitle}
          </p>
        </div>
      </div>
    </div>
  )
}
