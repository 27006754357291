import React from "react"
import { v4 as uuidv4 } from "uuid"

import logo_theodoor from "../../images/logo_theodoor.svg"
import logo_airstamp from "../../images/logo_airstamp_vector.svg"
import logo_koemmerling from "../../images/partner_2_koemmerling.png"
import logo_elumatec from "../../images/partner_3_elumatec.png"
import logo_trocal from "../../images/partner_4_trocal.png"
import logo_wicona from "../../images/wicona.jpg"

const partners = [
  {
    name: "Elumatec",
    imgPath: logo_elumatec,
    alt: "Elumatec Logo",
  },
  {
    name: "Trocal",
    imgPath: logo_trocal,
    alt: "Trocal Logo",
  },
  {
    name: "KBE",
    imgPath: logo_wicona,
    alt: "KBE Logo",
  },
  {
    name: "Theodoor",
    imgPath: logo_theodoor,
    alt: "Theodoor Logo",
  },
  {
    name: "AirStamp Zeiterfassung",
    imgPath: logo_airstamp,
    alt: "AirStamp Logo",
  },
  {
    name: "Kömmerling",
    imgPath: logo_koemmerling,
    alt: "Kömmerling Logo",
  },
]

export default function PartnerLogos() {
  return (
    <div className="bg-white">
      <p className="mt-1 text-3xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-4xl">
        Unsere Partner
      </p>
      <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
        {partners.map(partner => {
          return (
            <div
              key={uuidv4()}
              className="col-span-1 flex flex-col items-center justify-center py-8 px-8 bg-gray-50"
            >
              <img
                className="max-h-16"
                src={partner.imgPath}
                alt={partner.alt}
              />
              <p className="font-bold uppercase mt-8 text-gray-600">
                {partner.name}
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}
